import React, { useEffect, useState } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import Loading from '../components/Loading';

function MySubscription() {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMySubscriptions = async () => {

      const captainData = getItemFromLocalStorage('captainProfile');
      let formData = new FormData();
      formData.append('token', captainData.captain_access_token);

      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          url: `${params.baseURL}${params.endpoints.mySubscriptions}`,
          data: formData
        });

        if (response.data.items && Array.isArray(response.data.items)) {
          console.log(response.data);
          setData(response.data.items);
        } else {
          setError(response.message || 'Error in fetching data.');
        }
      } catch (error) {
        setError('Error fetching My Subscriptions.');
      } finally {
        setLoading(false);
      }
    };

    fetchMySubscriptions();
  }, []);

  if (loading) {
    return <div><Loading /></div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <div className='ms-section'>
      {data.length > 0 ? (<>
        {data.map(item => (
            <div className='ms-box'>
                <div className='ms-box-header'>
                    <h4>{item.subscription_name} -
                      <span className='ms-price-title'> {item.subscription_final_rate_display} / {item.subscription_tenure_display}</span>
                    </h4>                               
                    <div className='ms-body-flex-item'>
                        <p className={`ms-purchase-status ${item.subscription_status === 2 ? 'active' : 'inactive'}`}>{item.subscription_status_display}</p>
                    </div>
                </div>
                <div className='ms-box-body'>
                    <div className="row ms-features">
                        <h5 className='ms-price-title'>Features</h5>
                        {item.subscription_features.map(feature => (
                            <div className="col-md-3 ms-feature">
                                <div className="ms-feature-point">
                                    {feature.feature_code === 'TAGPHOTO' && (
                                      <img src='/assets/img/icons/fs_post_active.svg' alt='' className='subs-icon' />
                                    )}
                                    {feature.feature_code === 'TAGREVIEW' && (
                                      <img src='/assets/img/icons/fs_tagged_active.svg' alt='' className='subs-icon' />
                                    )}
                                    {feature.feature_code === 'LISTING' && (
                                      <img src='/assets/img/icons/fs_add_buss_active.svg' alt='' className='subs-icon' />
                                    )}
                                    {feature.feature_code === 'BANNER' && (
                                      <img src='/assets/img/icons/fs_banner_active.svg' alt='' className='subs-icon' />
                                    )}
                                    {feature.feature_code === 'TABLE' && (
                                      <img src='/assets/img/icons/fs_side_table_active.svg' alt='' className='subs-icon' />
                                    )}
                                    {feature.feature_code === 'MITEM' && (
                                      <img src='/assets/img/icons/fs_side_menu_active.svg' alt='' className='subs-icon' />
                                    )}
                                    {feature.feature_code === 'OITEM' && (
                                      <img src='/assets/img/icons/fs_side_offer_active.svg' alt='' className='subs-icon' />
                                    )}
                                    {feature.feature_code === 'PUSH' && (
                                      <img src='/assets/img/icons/fs_push_active.svg' alt='' className='subs-icon' />
                                    )}
                                    {feature.feature_code === 'CWPL' && (
                                      <img src='/assets/img/icons/fs_crowd_puller_icon.svg' alt='' className='subs-icon' />
                                    )}
                                    {feature.feature_code === 'NOAD' && (
                                      <img src='/assets/img/icons/fs_no_adv.svg' alt='' className='subs-icon' />
                                    )}
                                    <div className='mspfts'>
                                      <p>{feature.feature_title}</p>
                                      <span>{feature.usage}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='ms-box-footer'>
                    <div className='ms-body-flex-item'>
                        <p>Purchased on : {item.subscription_purchased_on}</p>
                        <p>Valid till : {item.subscription_valid_till_display}</p>
                    </div>
                </div>
            </div>
        ))}
        </>) : (<>
            <div className='empty-section'>
                <img src={'/assets/img/no_listing_gallery.svg'} alt='Fyre Search' />
                <p>My Subscription Not Found</p>
            </div>
        </>)}
      </div>
    </div>
  )
}

export default MySubscription;