import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import params from '../config/Params';
import colors from '../config/Colors';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import Loading from '../components/Loading';
import Drawer from '@mui/material/Drawer';

const getColorCode = (colorName) => {
  const color = colors.find(c => c.name === colorName);
  return color ? color.code : '#FFFFFF';
};

function Plans() {

  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [subscriptionId, setSubscriptionId] = useState('');
  const [priceid, setPriceId] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [couponMessage, setCouponMessage] = useState('');
  const [notFound, setNotFound] = useState(false);
  
  const errorRef = useRef(null);
  const successMessageRef = useRef(null);

  useEffect(() => {
    if ((error && errorRef.current) || (successMessage && successMessageRef.current)) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [error, successMessage]);

  useEffect(() => {
    if (error && errorRef.current) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [error]);

  useEffect(() => {
    const fetchSubscriptions = async () => {

      const captainData = getItemFromLocalStorage('captainProfile');
      let formData = new FormData();
      formData.append('token', captainData.captain_access_token);

      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          url: `${params.baseURL}${params.endpoints.getSubscriptions}`,
          data: formData
        });

        if (response.data.status === 'found') {
          setData(response.data.subscriptions);
          setNotFound(false);
        } else if (response.data.status === 'notfound') {
          setNotFound(true); 
          //{/*setError(response.data.msg);*/}
        } else {
          setError(response.message || 'Error in fetching data.');
        }
      } catch (error) {
        setError('Error fetching My Subscriptions.');
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptions();
  }, []);

  const settings = {
    dots: true,
    arrows: false,
    infinite: data.length > 1,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  const openCouponBox = (id, price_id) => {
    setDrawerOpen(true);
    setSubscriptionId(id);
    setPriceId(price_id);
  };

  const applyCoupon = async (couponCode) => {

    const captainData = getItemFromLocalStorage('captainProfile');

    let formData = new FormData();
    formData.append('token', captainData.captain_access_token);
    formData.append('subscription_id', subscriptionId);
    formData.append('price_variant_id', priceid);
    formData.append('coupon_code', couponCode);

    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        url: `${params.baseURL}${params.endpoints.checkCoupon}`,
        data: formData
      });

      if (response.data.status === 'ok') {
        setIsPopupOpen(true);
        setDrawerOpen(false);
        setCouponMessage(response.data.msg);
      } else if (response.data.status === 'error') {
        setError(response.data.list);
      } else {
        setError('Error in saving data.');
      }
    } catch (err) {
      setError('An error occurred while saving data.');
    }
  }

  const skipCoupon= () => {
    setIsPopupOpen(true);
    setDrawerOpen(false);
    setCouponMessage('');
  }

  const handlePayment = async (id, price_id) => {

    const captainData = getItemFromLocalStorage('captainProfile');

    let formData = new FormData();
    formData.append('subscription_id', id);
    formData.append('price_variant_id', price_id);
    formData.append('token', captainData.captain_access_token);
    formData.append('coupon_code', couponCode);

    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        url: `${params.baseURL}${params.endpoints.subscribeIt}`,
        data: formData
      });

      if (response.data.status === 'ok') {
        //console.log(response.data.captain_subscription_info.order_id);

        if (response.data.captain_subscription_info.subscription_status === 1)  { 
          try {
            const options = {
              key: "rzp_live_avdyHSL7PVwRiu",
              key_secret: "Mji5MGv4b7gxNyqtf0LbBYtf",
              amount: response.data.captain_subscription_info.subscription_final_rate * 100,
              currency: 'INR',
              name: 'FYRE SEARCH',
              description: response.data.captain_subscription_info.subscription_name,
              order_id: response.data.captain_subscription_info.order_id,
              handler: async function (response) {
                alert(`Payment successful! Payment ID: ${response.razorpay_payment_id}`);
                let pData = new FormData();
                pData.append('payment_id', response.razorpay_payment_id);
                pData.append('token', captainData.captain_access_token);
                const verifyResponse = await axios({
                  method: 'post',
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  },
                  url: `${params.baseURL}${params.endpoints.verifyPg}`,
                  data: pData
                });
                if (verifyResponse.data.status === 'ok') {
                  alert(`Payment successful! ${verifyResponse.data.msg}`);
                } else {
                  setError(verifyResponse.data.list || 'Payment verification failed.');
                }
              },
              prefill: {
                name: captainData.business_legal_name,
                email: captainData.business_contact_email,
                contact: captainData.business_contact_number
              },
              theme: {
                color: '#880d4e'
              }
            };      
            const rzp = new window.Razorpay(options);
            rzp.open();
          } catch (error) {
            console.error('Error during payment:', error);
          }
        } else {
          setSuccessMessage(response.data.msg)
        }

      } else if (response.data.status === 'error') {
        setError(response.data.list);
      } else {
        setError('Error in saving data.');
      }
    } catch (err) {
      setError('An error occurred while saving data.');
    }
  }

  const handleYesClick = () => {
    setDrawerOpen(false);
    setIsPopupOpen(false);
    handlePayment(subscriptionId, priceid);
  };

  if (loading) {
    return <div><Loading /></div>;
  }

  return (
    <div>
        <article>
          {successMessage && <div className="alert alert-success mb-4" ref={successMessageRef}>{successMessage}</div>}
          {error && <div className="alert alert-danger" ref={errorRef}>{error}</div>} 

          {notFound ? (
            <div className="text-center text-uppercase">
              <img src='/assets/img/no_subscription_found.png' alt='' style={{width:'300px'}} className='img-fluid' />
              <h4>Subscription Unavailable</h4>
              <p>The FS subscription is currently unavailable in your city. Please get in touch with our team for updates, and we'll notify you once it's enabled.</p>
            </div>
          ) : (
            <section className="row row--wrap mt-5"> 

              <Slider {...settings}>
                {data.map(item => (                    
                  <div>
                    <section className="pricing-card pricing-card--outline" style={{backgroundColor:getColorCode(item.color)}}>
                        <div className="pricing-card__summary">
                            <div className="pricing-card__head">
                                <h3 className="pricing-card__tier">{item.subscription_name}</h3>
                            </div>
                        </div>
                        <span class="pricing-line"></span>
                        <ul className="pricing-card__features">
                            {item.subscription_features.map(feature => (
                                <li className="pricing-card__feature">
                                    {feature.feature_code === 'TAGPHOTO' && (
                                      <img src='/assets/img/icons/fs_post_active.svg' alt='' className='subs-icon' />
                                    )}
                                    {feature.feature_code === 'TAGREVIEW' && (
                                      <img src='/assets/img/icons/fs_tagged_active.svg' alt='' className='subs-icon' />
                                    )}
                                    {feature.feature_code === 'LISTING' && (
                                      <img src='/assets/img/icons/fs_add_buss_active.svg' alt='' className='subs-icon' />
                                    )}
                                    {feature.feature_code === 'BANNER' && (
                                      <img src='/assets/img/icons/fs_banner_active.svg' alt='' className='subs-icon' />
                                    )}
                                    {feature.feature_code === 'TABLE' && (
                                      <img src='/assets/img/icons/fs_side_table_active.svg' alt='' className='subs-icon' />
                                    )}
                                    {feature.feature_code === 'MITEM' && (
                                      <img src='/assets/img/icons/fs_side_menu_active.svg' alt='' className='subs-icon' />
                                    )}
                                    {feature.feature_code === 'OITEM' && (
                                      <img src='/assets/img/icons/fs_side_offer_active.svg' alt='' className='subs-icon' />
                                    )}
                                    {feature.feature_code === 'PUSH' && (
                                      <img src='/assets/img/icons/fs_push_active.svg' alt='' className='subs-icon' />
                                    )}
                                    {feature.feature_code === 'CWPL' && (
                                      <img src='/assets/img/icons/fs_crowd_puller_icon.svg' alt='' className='subs-icon' />
                                    )}
                                    {feature.feature_code === 'NOAD' && (
                                      <img src='/assets/img/icons/fs_no_adv.svg' alt='' className='subs-icon' />
                                    )}
                                    <div className="pricing-feature_points">
                                        <p>{feature.feature_title}</p>
                                        <span className="pricing-points_desc">{feature.feature_description}</span>
                                        <span>{feature.feature_allowed_unit_description}</span>
                                    </div>
                                    {feature.yesorno ? <i className="fas fa-check"></i> : <i className="fas fa-times"></i>}
                                </li>
                            ))}
                        </ul>
                        {item.subscription_pricing.map(price => (
                            <button className="pricing-button pricing-card__action" 
                                onClick={() => openCouponBox(
                                    item.subscription_id, 
                                    price.price_variant_id
                                )}>
                                <span className='osp'>{price.price}{' '}{item.currency_code}</span>{price.final_price_display_with_validity}
                            </button>
                        ))}
                    </section>
                  </div>
                ))}
              </Slider>
            </section>
          )}
        </article>

        <Drawer
          anchor='bottom'
          className='drawer'
          open={isDrawerOpen}
          onClose={() => setDrawerOpen(false)}
      >
          <div className='drawer-content post-popup'>
              <div className='post-popup-box' style={{padding: '50px 10px', width: '500px', maxWidth: '500px'}}>
                <div>
                  <span className='drawer-close-btn' onClick={() => setDrawerOpen(false)}>
                    <img src={'/assets/img/icons/close_icon.svg'} className='w-100' alt='' />
                  </span>
                </div>
                  <div className='popup-title' style={{marginBottom: '1rem', fontWeight: '600' }}>REEDEM COUPON</div>
                  <div className='popup-options'>
                    <div>
                      <div className="form-floating">
                        <input type="text" id="coupon_code" className="form-control" value={couponCode} onChange={(e) => setCouponCode(e.target.value.toUpperCase())} required />
                        <label htmlFor="coupon_code">Enter Coupon Code</label>
                      </div>
                    </div>
                    <div className='coupon-drawer-footer'>
                      <button className="btn btn-primary" onClick={() => skipCoupon()}>Skip</button>
                      <button className="btn btn-success" onClick={() => applyCoupon(couponCode)}>Proceed</button>
                    </div>
                  </div>
              </div>
          </div>
      </Drawer>

      {isPopupOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm!</h5>
              <span className="popup-close" onClick={() => setIsPopupOpen(false)}>&times;</span>
            </div>
            <div className="modal-body">
              <p>Would you like to subscribe this plan. {couponMessage}</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn" onClick={() => setIsPopupOpen(false)}>No</button>
              <button type="button" className="btn" onClick={handleYesClick}>Yes</button>
            </div>
          </div>
        </div>
      )}

    </div>
  )
}

export default Plans;